.react-calendar {
    width: 100%;
}
.react-calendar__tile {
    min-height: 80px;
}
.react-calendar button {
  margin: 0;
  border: 1px solid #dddddd;
  outline: none;
  text-align: left;
  vertical-align: top;
}
